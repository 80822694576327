














































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'TorontoCHMB41HQuiz1Question3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        resonanceStructures: [],
        mostStable: [],
      } as {resonanceStructures: string[]; mostStable: string[]},
    };
  },
  computed: {
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    imagePrefix(): string {
      return `/img/assignments/UofT/CHMB41H/quiz-1/question-3/v${this.version}-`;
    },
    choices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], this.seed);
    },
    moleculeImage(): string {
      return this.imagePrefix + 'molecule.png';
    },
    resonanceImages(): string[] {
      return this.choices.map((l: string) => this.imagePrefix + 'resonance' + l + '.png');
    },
  },
  watch: {
    'inputs.resonanceStructures'(value: string[], oldValue: string[]) {
      if (oldValue.length > value.length) {
        const diff = oldValue.filter((v) => !value.includes(v));
        this.inputs.mostStable = this.inputs.mostStable.filter((v) => !diff.includes(v));
      }
    },
    'inputs.mostStable'(value: string[], oldValue: string[]) {
      if (value.length > oldValue.length) {
        const diff = value.filter((v) => !oldValue.includes(v))[0];
        if (!this.inputs.resonanceStructures.includes(diff)) {
          this.inputs.resonanceStructures.push(diff);
        }
      }
    },
  },
});
